import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Paper, Typography, TextField, Modal } from "@material-ui/core";

import CloseRounded from '@material-ui/icons/CloseRounded';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from "@material-ui/data-grid";

import { CircularProgress, IconButton, Collapse } from '@material-ui/core';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Box from '@material-ui/core/Box'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import moment from 'moment-timezone';

import { CSVLink } from "react-csv";

import { customersColors } from "services/constants";

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import CustomPagination from 'pages/orders/CustomPagination';
import ColoredLinearProgress from 'pages/components/LineProgress';
import InvoiceCollapse from 'pages/components/InvoiceCollapse';
import Home from 'pages/home';

// ... Tookan
import { createMultipleTasks, createGroups } from "services/tookan";
// Actions
import { Creators } from 'store/ducks/orders';
import { Creators as CreatorsClients } from 'store/ducks/clients';
import DataGridStores from './DataGridStores';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const statusList = [
  {status: 'REQUEST_RECEIVED', text: 'Pedido Recebido'},
  {status: 'PICKUP_ACCEPTED', text: 'Coleta aceita'},
  {status: 'PICKUP_STARTED', text: 'Coleta iniciada'},
  {status: 'PICKUP_SUCCESSFUL', text: 'Sucesso na coleta'},
  {status: 'PICKUP_FAILURE', text: 'Falha na coleta'},
  {status: 'PICKUP_IN_PROGRESS', text: 'Coleta em andamento'},
  {status: 'PICKUP_CANCELED', text: 'Coleta cancelada'},
  {status: 'PICKUP_DELETED', text: 'Coleta apagada'},
  {status: 'DELIVERY_ACCEPTED', text: 'Pedido de entrega aceito'},
  {status: 'DELIVERY_STARTED', text: 'Pedido de entrega iniciado'},
  {status: 'DELIVERY_SUCCESSFUL', text: 'Sucesso na entrega'},
  {status: 'DELIVERY_FAILURE', text: 'Falha na entrega'},
  {status: 'DELIVERY_IN_PROGRESS', text: 'Entrega em andamento'},
  {status: 'DELIVERY_CANCELED', text: 'Entrega cancelada'},
  {status: 'DELIVERY_DELETED', text: 'Entrega apagada'}
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  conteiner:{
    fontSize: 14, 
    textAlign: 'justify'
  },
  titulo: {
    margin: 0, 
    padding: 0, 
    fontSize: 14, 
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor:'#ddd',
    color: '#000'

  },

  dadosh1: {
    fontSize: 16, 
    textAlign: 'left', 
    fontWeight: 400,
    color: '#000000DE'
  },

  dadosTitulo: {
    fontSize: 16, 
    textAlign: 'left', 
    fontWeight: 400,
    color: '#0000008A'
  },

  dadosh2:{
    fontSize: 16, 
    textAlign: 'justify', 
    fontWeight: 400,
    color: '#000000DE'
  
  },

  svg:{
    color: '#000',
    width: 50,
    height: 20
  },
  vCenter:{
    display: 'flex',
    alignItems: 'center'
  },
  btnExportCSV:{
    border: '1px solid gray',
    borderRadius: 5,
    padding: 5,
    color: 'gray',
    paddingLeft: 10,
    paddingRight: 10,
    textDecoration: 'none',
    marginLeft: 20,
    marginRight: 10
  }
  
}));

// moment.tz.setDefault("America/Sao_Paulo");

const Index = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const { user } = useSelector(state => state.signin);
  const { pending, orders, ftp } = useSelector(state => state.orders);
  const { pending: pendingClients, clients } = useSelector(state => state.clients);

  const [filterType, setFilterType] = useState(0);
  const [searchMode, setSearchMode] = useState(0);
  const [gridMode, setGridMode] = useState(0);

  const [dialogOpened, setDialogOpened] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [statusSelecteds, setStatusSelecteds] = useState([]);
  
  const [filterText, setFilterText] = useState('');
  const [selectedProvider, setSelectedProvider] = useState({});
  
  const [filterDateName, setFilterDateName] = useState('updated_at');
  const [strSelectedDateFrom, setStrSelectedDateFrom] = useState(moment().utc().format("DD/MM/YYYY 00:00:00"));
  const [strSelectedDateTo, setStrSelectedDateTo] = useState(moment().utc().format("DD/MM/YYYY 23:59:59"));
  const [selectedDateFrom, setSelectedDateFrom] = useState(moment(strSelectedDateFrom, 'DD/MM/YYYY HH:mm:ss'));
  const [selectedDateTo, setSelectedDateTo] = useState(moment(strSelectedDateTo, 'DD/MM/YYYY HH:mm:ss'));

  const [storesGroups, setStoresGroups] = useState('');
  const [pendingDispatchStores, setPendingDispatchStores] = useState(false);
  const [selectedPickupDate, setSelectedPickupDate] = useState(moment());
  const [strSelectedPickupDate, setStrSelectedPickupDate] = useState(moment());
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(moment());
  const [strSelectedDeliveryDate, setStrSelectedDeliveryDate] = useState(moment());
  

  const csvHeaders = [
    { label: "ID", key: "id" },
    { label: "Provider", key: "provider" },
    { label: "Status", key: "status" },
    { label: "Motivo", key: "motive" },
    { label: "Código Resposta", key: "code_response" },
    { label: "Mensagem Resposta", key: "message_response" },
    { label: "Data de envio", key: "shipped_date" },
    { label: "Nº Ordem", key: "order_number" },
    { label: "Cod. Rastreio", key: "tracking_code" },
    { label: "Itens", key: "items_quantity" },
    { label: "NF Série", key: "invoice_series" },
    { label: "NF Número", key: "invoice_number" },
    { label: "NF Chave", key: "invoice_key" },
    { label: "Cubagem", key: "weight" },
    { label: "Tipo encomenda", key: "shipment_order_type" },
    { label: "Ref", key: "shipping_reference" },
    { label: "Nome", key: "first_name" },
    { label: "Sobrenome", key: "last_name" },
    { label: "Telefone", key: "phone" },
    { label: "Celular", key: "cellphone" },
    { label: "E-mail", key: "email" },
    { label: "Endereço", key: "shipping_address" },
    { label: "Número", key: "shipping_number" },
    { label: "Complemento", key: "shipping_additional" },
    { label: "Bairro", key: "shipping_quarter" },
    { label: "Cidade", key: "shipping_city" },
    { label: "Estado", key: "shipping_state" },
    { label: "País", key: "shipping_country" },
    { label: "CEP", key: "shipping_zip_code" },
    { label: "Enviado por", key: "origin_name" },
    { label: "CNPJ", key: "sender_cnpj" },
    { label: "Telefone", key: "origin_customer_phone" },
    { label: "Endereço", key: "origin_street" },
    { label: "Número", key: "origin_number" },
    { label: "Complemento", key: "origin_additional" },
    { label: "Ref", key: "origin_reference" },
    { label: "Bairro", key: "origin_quarter" },
    { label: "Cidade", key: "origin_city" },
    { label: "Estado", key: "origin_state_code" },
    { label: "CEP", key: "origin_zip_code" },
    { label: "Premium?", key: "is_premium" },
    { label: "Expedidor CNPJ", key: "cnpj_expeditor" },
    { label: "Criado em", key: "created_at" },
  ];
 
  useEffect(() => {
    dispatch(CreatorsClients.fetchClients())
  },[]);

  const handlerFilter = (newPage) => {
    if (filterType === 0) {
      if(searchMode === 0) {
        const dateFrom = selectedDateFrom.format("YYYY-MM-DDTHH:mm:ss");
        const dateTo = selectedDateTo.format("YYYY-MM-DDTHH:mm:ss");
        console.log('---', dateFrom, dateTo);
        
        const selectedStatus = statusSelecteds.map(item => (item.status)).join(',');
        const providerName = selectedProvider?.provider || '';
        
        const newPageSize = orders?.pageSize || 17;
        dispatch(Creators.fetchOrdersPagened(providerName, filterDateName, dateFrom, dateTo, newPage || 1, newPageSize, selectedStatus));
      } else {
        const providerName = selectedProvider?.provider || '';
        const dateFrom = selectedDateFrom.format("YYYY-MM-DDTHH:mm:ss");
        const dateTo = selectedDateTo.format("YYYY-MM-DDTHH:mm:ss");

        console.log('---', dateFrom, dateTo);
        const selectedStatus = statusSelecteds.map(item => (item.status)).join(',');
        dispatch(Creators.fetchByOrderProvider(providerName, filterDateName, dateFrom, dateTo, selectedStatus));
      }
    } else {
      dispatch(Creators.fetchByOrderNumber(filterText));
    }
  }

  const handleFirstPage = () => {
    handlerFilter(1);
  }

  const handlePrevPage = () => {
    handlerFilter(Number(orders?.page) - 1);
  }

  const handleNextPage = () => {
    handlerFilter(Number(orders?.page) + 1);
  }

  const handleLastPage = () => {
    handlerFilter(orders?.totalPages);
  }

  // const handlePageChange = (newPage) => {
  //   if(Number(newPage) >= 0)
  //     handlerFilter(newPage);
  //   // Dispare uma ação para buscar a próxima página via Redux
  // };
  
  const handleChange = (event) => {
    const { target: { value }, } = event;
    setStatusSelecteds(value);
  };

  const handlerImportFTP = () => {
    //...
    if(filterType === 0) {
      dispatch(Creators.downloadFtpOrders(filterText));
    }
  }

  const onDateChangeSelectedDateFrom = (date, value) => {
    setSelectedDateFrom(date);
    setStrSelectedDateFrom(value);
  };

  const onDateChangeSelectedDateTo = (date, value) => {
    setSelectedDateTo(date);
    setStrSelectedDateTo(value);
  };

  const onDateChangeSelectedDatePickup = (date, value) => {
    setSelectedPickupDate(date);
    setStrSelectedPickupDate(value);
  };

  const onDateChangeSelectedDateDelivery = (date, value) => {
    setSelectedDeliveryDate(date);
    setStrSelectedDeliveryDate(value);
  };

  const dateFormatter = str => {
    return str;
  };

  const Line = (props) => {
    const classes = useStyles();
    const {label, text, center, isWhatsapp} = props;
    return (
      <div style={{padding:2, width:'100%'}}>
        {label
          ? <Typography variant="body2" color="textSecondary">
              {label}
            </Typography>
          : null
        }
        {center
        ? <div className={classes.center}>
            <Typography color="primary" component="h1" variant="h5">
              {text} {isWhatsapp ? <WhatsApp /> : null}
            </Typography>
          </div>
        : <Typography>
            {text} {isWhatsapp ? <WhatsApp /> : null}
          </Typography>
        }
      </div>
    )
  }
  
  const getProviderColor = (provider) => {
    const color = customersColors.find(c => c.provider === provider)?.color;

    return color ? color : '#696969';
  }

  const columns = [
    { field: "id", headerName: " ", hide:true },
    { field: "created_at", headerName: "Criado em", width: 160, renderCell: (params) => moment(params?.value).utc().format('DD/MM/YYYY HH:mm:ss') },
    { field: "updated_at", headerName: "Atualizado em", width: 160, renderCell: (params) => moment(params?.value).utc().format('DD/MM/YYYY HH:mm:ss') },
    // { field: "shipped_date", headerName: "Data Envio", width: 160, renderCell: (params) => moment(params?.value).utc().format('DD/MM/YYYY HH:mm:ss') },
    { field: "provider", headerName: "Provider", width: 150, 
      renderCell: (params) => (
        <span>
          <span style={{backgroundColor: getProviderColor(params?.value), width:10, height:10, borderRadius:5, display:'inline-flex'}}></span>
          
          <span style={{fontWeight: 'bold', color: getProviderColor(params?.value), marginLeft:5}}>{params?.value}</span>
        </span>
      )
    },
    { field: "order_number", headerName: "Nº Ordem", width: 200, 
      renderCell: (params) => (
        <span style={{fontWeight: 'bold', color: 'blue', marginLeft:5}}>{params?.value}</span>
      )
    },
    { field: "tracking_code", headerName: "Cod. Rastreio", width: 250, 
      renderCell: (params) => (
        <span style={{fontWeight: 'bold', color: 'green', marginLeft:5}}>{params?.value}</span>
      )
    },
    { field: "invoice_key", headerName: "NF Chave", width: 400 },
    { field: "invoiceSeries", headerName: "NF Serie", width: 150 },
    { field: "invoice_number", headerName: "NF Número", width: 150 },
    { field: "items_quantity", headerName: "Itens", width: 120, renderCell: (params) => Number(params?.value).toLocaleString(undefined, { maximumFractionDigits: 3, minimumFractionDigits: 0 }) },
    { field: "shipment_order_type", headerName: "Tipo", width: 150 },
    { field: "motive", headerName: "Status", width: 300, renderCell: (params) => <div style={{width:'100%', fontWeight:'bold', textAlign:'center', backgroundColor:'#f1f1f1'}}>{params?.value}</div> },
    { field: "code_response", headerName: "Código", width: 150, renderCell: (params) => <div style={{width:'100%', fontWeight:'bold', textAlign:'center', backgroundColor:'#f1f1f1'}}>{params?.value}</div> },
    { field: "message_response", headerName: "Mensagem", width: 300 },
    { field: "first_name", headerName: "Nome", width: 150, renderCell: (params) => <div style={{width:'100%', fontWeight:'bold', textAlign:'center', color:'blue', backgroundColor:'#f1f1f1'}}>{params?.value}</div> },
    { field: "last_name", headerName: "Sobrenome", width: 300 },
    { field: "cellphone", headerName: "Celular", width: 150 },
    { field: "email", headerName: "E-mail", width: 300 },
    { field: "shipping_address", headerName: "Endereço", width: 400, renderCell: (params) => <div style={{width:'100%', fontWeight:'bold', color:'green', backgroundColor:'#f1f1f1'}}>{params?.value}</div> },
    { field: "shipping_number", headerName: "Número", width: 150, renderCell: (params) => <div style={{width:'100%', fontWeight:'bold', textAlign:'center', color:'green', backgroundColor:'#f1f1f1'}}>{params?.value}</div> },
    { field: "shipping_quarter", headerName: "Bairro", width: 300, renderCell: (params) => <div style={{width:'100%', fontWeight:'bold', color:'green', backgroundColor:'#f1f1f1'}}>{params?.value}</div> },
    { field: "shipping_additional", headerName: "Complemento", width: 300 },
    { field: "shipping_city", headerName: "Cidade", width: 300, renderCell: (params) => <div style={{width:'100%', fontWeight:'bold', color:'green', backgroundColor:'#f1f1f1'}}>{params?.value}</div> },
    { field: "shipping_state", headerName: "UF", width: 100, renderCell: (params) => <div style={{width:'100%', fontWeight:'bold', color:'green', backgroundColor:'#f1f1f1'}}>{String(params?.value).toUpperCase()}</div> },
    { field: "origin_name", headerName: "Empresa", width: 300 },
    { field: "origin_reference", headerName: "Origem", width: 300 },
    { field: "origin_customer_phone", headerName: "Origem Tel", width: 150 },
    { field: "origin_street", headerName: "Endereço", width: 400 },
    { field: "cnpj_expeditor", headerName: "CNPJ Expedidor", width: 200 }
  ];

  const handleFilterType = (event) => {
    const value = event.target.value;
    setFilterType(value);
    setFilterText('');
  };

  const getFormatedExportData = () =>  {
    const DATA = [].concat(orders?.data).map((o, idx) => {
      const newItem = {
        shipped_date: o?.shipped_date ? moment(o?.shipped_date).utc().format('DD/MM/YYYY HH:mm:ss') : '',
        items_quantity: Number(o?.items_quantity).toLocaleString(undefined, { maximumFractionDigits: 3, minimumFractionDigits: 0 }) || 0,
        created_at: moment(o?.created_at).utc().format("DD/MM/YYYY HH:mm:ss") || ''
      }

      return {...o, ...newItem}
    })
    return DATA
  }

  const handleShowDialogDispatchStores = async () => {
    setPendingDispatchStores(true);
    try {
      // const filteredOrders = [...orders].filter(o => o.status === "REQUEST_RECEIVED");
      const groups = await createGroups({provider:selectedProvider, orders:orders?.data || []});
      
      setStoresGroups(groups);
      if ( groups && [...groups].length > 0 ) {
        setDialogOpened(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPendingDispatchStores(false);
    }
  }

  const handlerDispatchStores = async () => {
    setPendingDispatchStores(true);
    try {
      for await (let group of storesGroups) {
        group['status'] = 1;
        const response = await createMultipleTasks({group , pickupDatetime:strSelectedPickupDate, deliveryDatetime:strSelectedDeliveryDate})
        group['status'] = response.status;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPendingDispatchStores(false);
    }
  }

  const handleProviderSelected = (event) => {
    const obj = event.target.value;
    setSelectedProvider(obj);
  }

  return (
    <Home title={'Ordens de Serviço'}>
      {pending ? <ColoredLinearProgress /> : null }
      <Paper className={classes.paper}>
        <Grid container spacing={4}>
          <Grid item>
            <Box sx={{ paddingTop:20 }}>
              <FormControl fullWidth>
                <InputLabel>Buscar por:</InputLabel>
                <Select
                  value={filterType}
                  onChange={handleFilterType}
                >
                  <MenuItem value={0}>Provider</MenuItem>
                  <MenuItem value={1}>Nº Ordem</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} sm={3}>
            {filterType === 0 &&              
              <Box sx={{paddingTop:20 }}>
                {pendingClients 
                  ? <CircularProgress size="20px" /> 
                  : <FormControl fullWidth>
                      <InputLabel>Provider {filterText}</InputLabel>
                      <Select
                        value={selectedProvider}
                        onChange={e => handleProviderSelected(e)}
                      >
                        {
                          [...clients].map((item, idx) => (
                            <MenuItem key={`${idx}`} value={item}>{item.name}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                }
              </Box>
            }
            {filterType === 1 &&
              <TextField disabled={pending}
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={filterType === 0 ? 'Empresa/Provider' : 'Número da Ordem'}
                autoFocus
              />
            }
          </Grid>
          { filterType === 0  &&
            <Grid item xs={12} sm={4}>
              <Box sx={{paddingTop:20 }}>
                <FormControl fullWidth>
                  <InputLabel>{`Status (${statusSelecteds.length} selecionados)`}</InputLabel>
                  <Select
                    labelId="status-multiple-checkbox-label"
                    id="status-multiple-checkbox"
                    multiple
                    value={statusSelecteds}
                    onChange={handleChange}
                    // input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.map(item => (
                      <span style={{fontWeight:'bold'}}>{`${item?.text}; `}</span>
                    ))}
                    MenuProps={MenuProps}
                  >
                    {statusList.map((item) => (
                      <MenuItem key={`status_${item.status}`} value={item}>
                        <Checkbox key={`status_checkbox_${item.status}`} checked={statusSelecteds.includes(item)} />
                        <ListItemText primary={item.text} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          }

          <Grid item>
            <Box sx={{ paddingTop:20 }}>
              <FormControl fullWidth>
                <InputLabel>Modo</InputLabel>
                <Select
                  value={searchMode}
                  onChange={e => setSearchMode(e.target.value)}
                >
                  <MenuItem value={0}>Enconômico</MenuItem>
                  <MenuItem value={1}>Alto Custo</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item>
            <Box sx={{ paddingTop:20 }}>
              <FormControl fullWidth>
                <InputLabel>Exibir</InputLabel>
                <Select
                  value={gridMode}
                  onChange={e => setGridMode(e.target.value)}
                >
                  <MenuItem value={0}>Tabela</MenuItem>
                  <MenuItem value={1}>Página</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        
        <div style={{height:20}} />
        <Grid container spacing={3}>
          {filterType === 0 
            ? <>
                <Grid item>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel>Data de:</InputLabel>
                      <Select
                        value={filterDateName}
                        onChange={e => setFilterDateName(e.target.value)}
                      >
                        <MenuItem value={'created_at'}>Criação</MenuItem>
                        <MenuItem value={'updated_at'}>Atualização</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item >
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDateTimePicker disabled={pending}  components={['DateTimePicker', 'DateTimePicker']}
                      disableFuture
                      autoOk={true}
                      label="De:"
                      showTodayButton={true}
                      value={selectedDateFrom}
                      format="DD/MM/YYYY HH:mm:ss"
                      inputValue={strSelectedDateFrom}
                      onChange={onDateChangeSelectedDateFrom}
                      rifmFormatter={dateFormatter}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item  >
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDateTimePicker disabled={pending}
                      disableFuture
                      autoOk={true}
                      label="a:"
                      showTodayButton={true}
                      value={selectedDateTo}
                      format="DD/MM/YYYY HH:mm:ss"
                      inputValue={strSelectedDateTo}
                      onChange={onDateChangeSelectedDateTo}
                      rifmFormatter={dateFormatter}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </>
            : null
          }
          <Grid item className={classes.vCenter}>
            <Button disabled={pending} type="button" variant="contained" color="primary"
              onClick={()=>handlerFilter()}>Filtrar</Button>
            
            { orders?.data?.length > 0 
              ?<>
                <CSVLink data={getFormatedExportData()} headers={csvHeaders} className={classes.btnExportCSV}>
                  Exportar CSV
                </CSVLink>

                <Button disabled={pending} type="button" variant="contained" color="secondary"
                  onClick={handleShowDialogDispatchStores}>Despachar Lojas</Button>
              </>
              :null
            }
          </Grid>
        </Grid>

        <div style={{display:'flex', justifyContent:'flex-end'}}>
          

          {/* {(filterType === 0 && filterText !=== "") &&
            <Button disabled={pending} type="button" variant="contained" color="default"
              onClick={()=>handlerImportFTP()}>Importar FTP</Button>
          } */}
        </div>
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            { orders?.data ?
              <>
                { 
                  orders?.paginationMode === 'server' ?
                    <>
                      {
                        gridMode === 0 ?
                        <DataGrid rows={orders?.data} columns={columns} density="compact"
                          style={{minHeight: '70vh'}}

                          pagination
                          paginationMode={"server"}
                          rowCount={Number(orders?.total)}
                          page={Number(orders?.page)}
                          // onPageChange={handlePageChange}
                          autoPageSize={true}
                          // onPageSizeChange={handlePageSizeChange}
                          // pageSize={Number(orders?.pageSize)}
                          // disableColumnMenu={false}
                          // hideFooterSelectedRowCount={false}
                          components={{
                            Pagination: () => (
                              <CustomPagination
                                currentPage={Number(orders?.page)}
                                pageSize={Number(orders?.pageSize)}
                                totalPages={Number(orders?.totalPages)}
                                totalRecords={Number(orders?.total) || 0}
                                onFirstPage={handleFirstPage}
                                onPrevPage={handlePrevPage}
                                onNextPage={handleNextPage}
                                onLastPage={handleLastPage}
                              />
                            ),
                          }}
                        />
                      :
                        <>
                          <InvoiceCollapse data={orders.data} />
                          <CustomPagination
                              currentPage={Number(orders?.page)}
                              pageSize={Number(orders?.pageSize)}
                              totalPages={Number(orders?.totalPages)}
                              totalRecords={Number(orders?.total) || 0}
                              onFirstPage={handleFirstPage}
                              onPrevPage={handlePrevPage}
                              onNextPage={handleNextPage}
                              onLastPage={handleLastPage}
                            />
                        </>
                      }
                    </>
                  : 
                    <>
                      {
                        gridMode === 0 ?
                          <DataGrid rows={orders.data} columns={columns} density="compact"
                            style={{minHeight: '70vh'}}
                            autoPageSize={true} 
                            disableColumnMenu={false} 
                            hideFooterSelectedRowCount={false} />
                        :
                          <>
                            <InvoiceCollapse data={orders.data} />
                          </>
                      }
                    </>
                }
              </>
              :
              <>
                sem dados
              </>

            }
          </Paper>
        </Grid>
      </Grid>
      
      <Modal
        open={dialogOpened}
        onClose={() => setDialogOpened(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',height:'80%', width: '80%',bgcolor: 'background.paper',boxShadow: 24,p: 4, borderRadius:10}}>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <h2>Despachar Lojas</h2>
            </Grid>
            <Grid item xs={2} style={{display:'flex', justifyContent:'flex-end'}}>
              <IconButton onClick={()=>setDialogOpened(false)} style={{width:50, height:50, border:'1px solid gray'}}>
                <CloseRounded color='primary'/>
              </IconButton>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item >
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDateTimePicker disabled={pending}
                  disablePast
                  autoOk={true}
                  label="Data da Retirada"
                  showTodayButton={true}
                  value={selectedPickupDate}
                  format="DD/MM/YYYY HH:MM:ss"
                  inputValue={strSelectedPickupDate}
                  onChange={onDateChangeSelectedDatePickup}
                  rifmFormatter={dateFormatter}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item  >
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDateTimePicker disabled={pending}
                  disablePast
                  autoOk={true}
                  label="Data da Entrega"
                  showTodayButton={true}
                  value={selectedDeliveryDate}
                  format="DD/MM/YYYY HH:MM:ss"
                  inputValue={strSelectedDeliveryDate}
                  onChange={onDateChangeSelectedDateDelivery}
                  rifmFormatter={dateFormatter}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h2>Itens para despachar...</h2>
              <div style={{ height: 500, width: '100%' }}>
                <DataGridStores data={storesGroups} />
              </div>
            </Grid>

            <Grid item xs={12} >
              { pendingDispatchStores === true
                ? <ColoredLinearProgress />
                : <Grid container>
                    <Grid item xs={6}>
                      <Button disabled={pending} type="button" variant="contained" color="default"
                        onClick={()=>setDialogOpened(false)}>Cancelar</Button>
                    </Grid>
                    <Grid item xs={6} style={{justifyContent: 'end', display: 'flex'}}>
                      <Button disabled={pending} type="button" variant="contained" color="primary"
                        onClick={()=>handlerDispatchStores()}>Confirmar</Button>
                    </Grid>
                  </Grid>
              }
            </Grid>
            
          </Grid>

        </Box>
      </Modal>
    </Home>
  );
}

export default Index