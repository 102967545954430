import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  fetchOrders: [],
  fetchOrdersSuccess: ['orders'],
  fetchOrdersFailure: ['error'],

  fetchOrder: ['id'],
  fetchOrderSuccess: ['order'],
  fetchOrderFailure: ['error'],
  fetchByOrderNumber: ['orderNumber'],
  fetchByOrderNumberSuccess: ['orders'],
  fetchByOrderNumberFailure: ['error'],

  fetchOrdersPagened: ['provider', 'filterDateName', 'dateFrom', 'dateTo', 'page', 'pageSize', 'filterStatus'],
  fetchOrdersPagenedSuccess: ['orders'],
  fetchOrdersPagenedFailure: ['error'],

  fetchByOrderProvider: ['provider', 'filterDateName', 'dateFrom', 'dateTo', 'filterStatus'],
  fetchByOrderProviderSuccess: ['orders'],
  fetchByOrderProviderFailure: ['error'],

  fetchAllYear: [],
  fetchAllYearSuccess: ['data'],
  fetchAllYearFailure: ['error'],
  fetchByYear: ['year'],
  fetchByYearSuccess: ['data'],
  fetchByYearFailure: ['error'],
  fetchByDayByDay: [],
  fetchByDayByDaySuccess: ['data'],
  fetchByDayByDayFailure: ['error'],

  putOrder: ['order'],
  putOrderSuccess: ['order'],
  putOrderFailure: ['error'],
  resetOrderState: [],
  resetModified: [],

  downloadFtpOrders: ['provider'],
  downloadFtpOrdersSuccess: ['ftp'],
  downloadFtpOrdersFailure: ['error'],
})

/**
 * Handlers
 */
const STATE = {
  pending: false,
  order: null,
  orders: [],
  reportAllYear: [],
  reportByYear: [],
  reportByDayByDay: [],
  ftp: null,
  error: null
}
export const INITIAL_STATE = STATE;

// Order
const getOrder = (state, {id}) => {
  return {...state, pending: true, order: null, error: null}
};
const getOrderSuccess = (state, { order }) => {
  return {...state, order, pending: false, error: null}
};
const getOrderFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

// Orders
const getOrders = (state, {}) => {
  return {...state, pending: true, error: null}
};
const getOrdersSuccess = (state, { orders }) => {
  return {...state, orders, pending: false, error: null}
};
const getOrdersFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

// ByOrderNumber
const getByOrderNumber = (state, {orderNumber}) => {
  return {...state, pending: true, error: null}
};
const getByOrderNumberSuccess = (state, { orders }) => {
  return {...state, orders, pending: false, error: null}
};
const getByOrderNumberFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

// getOrderByPagened
const getOrderByPagened = (state, {provider, dateFrom, dateTo, page, pageSize, filterStatus}) => {
  return {...state, pending: true, error: null}
};
const getOrderByPagenedSuccess = (state, { orders }) => {
  return {...state, orders, pending: false, error: null}
};
const getOrderByPagenedFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

// ByOrderByProvider
const getByOrderProvider = (state, {provider, dateFrom, dateTo, filterStatus}) => {
  return {...state, pending: true, error: null}
};
const getByOrderProviderSuccess = (state, { orders }) => {
  return {...state, orders, pending: false, error: null}
};
const getByOrderProviderFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

// Report All Year
const getAllYear = state => {
  return {...state, pending: true, error: null}
};
const getAllYearSuccess = (state, { data }) => {
  return {...state, reportAllYear:data, pending: false, error: null}
};
const getAllYearFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

// Report By Year
const getByYear = state => {
  return {...state, pending: true, error: null}
};
const getByYearSuccess = (state, { data }) => {
  return {...state, reportByYear:data, pending: false, error: null}
};
const getByYearFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

// Report All by day by day
const getByDayByDay = state => {
  return {...state, pending: true, error: null}
};
const getByDayByDaySuccess = (state, { data }) => {
  return {...state, reportByDayByDay:data, pending: false, error: null}
};
const getByDayByDayFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

// Put Order
const updateOrder = (state, {order}) => {
  return {...state, pending: true, modified:false, error: null}
};
const updateOrderSuccess = (state, { order }) => {
  return {...state, order, modified:true, pending: false, error: null}
};
const updateOrderFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

//Reset Order State
const resetModified = state => {
  return {...state, modified:false}
}

//Reset Order State
const resetOrderState = state => {
  return STATE
}

// Put Order
const downloadFtpOrders = (state, {provider}) => {
  return {...state, pending: true, ftp:null, error: null}
};
const downloadFtpOrdersSuccess = (state, { ftp }) => {
  return {...state, ftp, pending: false, error: null}
};
const downloadFtpOrdersFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.FETCH_ORDER]: getOrder,
  [Types.FETCH_ORDER_SUCCESS]: getOrderSuccess,
  [Types.FETCH_ORDER_FAILURE]: getOrderFailure,
  [Types.FETCH_ORDERS]: getOrders,
  [Types.FETCH_ORDERS_SUCCESS]: getOrdersSuccess,
  [Types.FETCH_ORDERS_FAILURE]: getOrdersFailure,

  [Types.FETCH_BY_ORDER_NUMBER]: getByOrderNumber,
  [Types.FETCH_BY_ORDER_NUMBER_SUCCESS]: getByOrderNumberSuccess,
  [Types.FETCH_BY_ORDER_NUMBER_FAILURE]: getByOrderNumberFailure,

  //FETCH_ORDERS_PAGENED = fetchOrdersPagened
  [Types.FETCH_ORDERS_PAGENED]: getOrderByPagened,
  [Types.FETCH_ORDERS_PAGENED_SUCCESS]: getOrderByPagenedSuccess,
  [Types.FETCH_ORDERS_PAGENED_FAILURE]: getOrderByPagenedFailure,

  [Types.FETCH_BY_ORDER_PROVIDER]: getByOrderProvider,
  [Types.FETCH_BY_ORDER_PROVIDER_SUCCESS]: getByOrderProviderSuccess,
  [Types.FETCH_BY_ORDER_PROVIDER_FAILURE]: getByOrderProviderFailure,

  [Types.FETCH_ALL_YEAR]: getAllYear,
  [Types.FETCH_ALL_YEAR_SUCCESS]: getAllYearSuccess,
  [Types.FETCH_ALL_YEAR_FAILURE]: getAllYearFailure,
  [Types.FETCH_BY_YEAR]: getByYear,
  [Types.FETCH_BY_YEAR_SUCCESS]: getByYearSuccess,
  [Types.FETCH_BY_YEAR_FAILURE]: getByYearFailure,

  [Types.FETCH_BY_DAY_BY_DAY]: getByDayByDay,
  [Types.FETCH_BY_DAY_BY_DAY_SUCCESS]: getByDayByDaySuccess,
  [Types.FETCH_BY_DAY_BY_DAY_FAILURE]: getByDayByDayFailure,

  [Types.PUT_ORDER]: updateOrder,
  [Types.PUT_ORDER_SUCCESS]: updateOrderSuccess,
  [Types.PUT_ORDER_FAILURE]: updateOrderFailure,

  [Types.RESET_ORDER_STATE]: resetOrderState,
  [Types.RESET_MODIFIED]: resetModified,

  [Types.DOWNLOAD_FTP_ORDERS]: downloadFtpOrders,
  [Types.DOWNLOAD_FTP_ORDERS_SUCCESS]: downloadFtpOrdersSuccess,
  [Types.DOWNLOAD_FTP_ORDERS_FAILURE]: downloadFtpOrdersFailure,

})
